import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Image from "next/image";
import NextLink from "next/link";

import { FooterData } from "@/shared/api/common-api";
import { NavLink } from "@/shared/lib";
import { DownloadAppButton, LocaleSwitcher, Logo } from "@/shared/ui";

import { FooterContacts } from "./footer-contacts";
import { FooterNavMenu } from "./nav-menu";

export type AppsBadgesProps = {
  appStoreLink: string;
  googlePlayLink: string;
  variant?: "default" | "outlined";
};

const AppsBadges = ({
  appStoreLink,
  googlePlayLink,
  variant = "default",
}: AppsBadgesProps) => {
  return (
    <Stack direction="row" spacing={1}>
      <Box
        border="1px solid"
        borderColor={variant === "outlined" ? "neutral.50" : "transparent"}
        borderRadius={100}
        width="100%"
      >
        <DownloadAppButton brand="GooglePlay" href={googlePlayLink} />
      </Box>
      <Box
        border="1px solid"
        borderColor={variant === "outlined" ? "neutral.50" : "transparent"}
        borderRadius={100}
        width="100%"
      >
        <DownloadAppButton brand="AppStore" href={appStoreLink} />
      </Box>
    </Stack>
  );
};

type FooterTopProps = {
  links: NavLink[];
} & Pick<
  FooterData,
  | "socialTitle"
  | "socialLinks"
  | "googlePlayLink"
  | "contactsTitle"
  | "footerContact"
  | "loginButton"
  | "showAccountBtn"
>;

export const FooterTop = (props: FooterTopProps) => {
  const {
    socialTitle,
    socialLinks,
    googlePlayLink,
    links,
    footerContact,
    contactsTitle,
  } = props;

  const socialNetworks = (
    <Stack
      spacing={1.25}
      mt={{
        xs: 2,
        md: 1.5,
      }}
      direction="row"
      alignItems="flex-end"
    >
      {socialTitle ? (
        <Typography variant="text6">{socialTitle}</Typography>
      ) : null}
      <Stack spacing={1} direction="row" alignItems="center">
        {socialLinks?.map((item) => (
          <Box
            key={item.id}
            component={Link}
            href={item.link}
            sx={{
              display: "flex",
              width: 28,
              height: 28,
            }}
            target="_blank"
          >
            <Image
              src={item.icon.data?.attributes.url || ""}
              alt=""
              width={30}
              height={31}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );

  const contactsTitleNode = contactsTitle ? (
    <Typography
      variant="headline8"
      sx={{
        mb: 2,
      }}
    >
      {contactsTitle}
    </Typography>
  ) : null;

  return (
    <Box bgcolor="common.black" color="common.white">
      <Container
        sx={{
          alignItems: "center",
          direction: "row",
          display: "flex",
          justifyContent: "space-between",
          py: 3,
        }}
      >
        <Link component={NextLink} href="/">
          <Logo width="auto" variant="light" />
        </Link>
        <Box
          display={{
            xs: "none",
            md: "block",
          }}
        >
          <AppsBadges
            appStoreLink={googlePlayLink}
            googlePlayLink={googlePlayLink}
            variant="outlined"
          />
        </Box>
      </Container>
      <Divider
        sx={{
          bgcolor: "neutral.20",
        }}
      />

      <Container
        sx={{
          py: 3,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} order={{ xs: 7, md: 0 }}>
            {contactsTitleNode}
            <FooterContacts footerContact={footerContact} />
            {socialNetworks}
            <LocaleSwitcher color="neutral.100" />
          </Grid>

          <Grid item xs={12} md={6}>
            <FooterNavMenu links={links} />
            <Divider
              sx={{
                display: {
                  md: "none",
                },
                mx: -2,
                my: 3.5,
                bgcolor: "neutral.20",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Box
        display={{
          xs: "block",
          md: "none",
        }}
      >
        <Divider
          sx={{
            bgcolor: "neutral.20",
          }}
        />
        <Container
          sx={{
            py: 3,
          }}
        >
          <AppsBadges
            appStoreLink={googlePlayLink}
            googlePlayLink={googlePlayLink}
            variant="outlined"
          />
        </Container>
      </Box>
    </Box>
  );
};
