import { ApiInstance } from "@/shared/api/instance";
import { IImage } from "@/shared/types";

export type LinkTarget = "_blank" | "_self" | "_parent" | "_top" | string;

export type NavLinkItem = {
  id: number;
  text: string;
  to: string;
  __component: "shared.nav-link";
};

export type ExternalLinkItem = {
  id: number;
  text: string;
  href: string;
  target: LinkTarget;
  __component: "shared.external-link";
};

export type NavigationItem = NavLinkItem | ExternalLinkItem;

export type SocialLink = {
  icon: IImage;
  id: number;
  link: string;
};

export type FooterContact = {
  contact: string;
  icon: IImage;
  id: number;
  title: string;
};

export type FooterData = {
  address: string;
  contactsTitle: string;
  footerContact: Array<FooterContact>;
  googlePlayLink: string;
  id: number;
  requisites: string;
  socialLinks: Array<SocialLink>;
  socialTitle: string;
  allRightsReserved: string;
  loginButton: string;
  showAccountBtn: boolean | null;
};

export type HeaderData = {
  accountBtnText: string;
  menuBtnText: string;
  menuCloseText: string;
  showAccountBtn: boolean | null;
};

export type InternationalizationData = {
  currencyChar: string;
  id: number;
  thousandSeparator: string;
};

export type AmmanaFooterLegalData = {
  text1: string;
  image1: IImage;
  text2: string;
  subtext2: string;
  image2: IImage;
  image3: IImage;
  terms: string;
};

type AppFeatures = {
  chatbot: {
    enabled: boolean;
    buttonText?: string;
    buttonImage?: IImage;
    buttonColor?: string;
  };
};

type RepaymentOptions = {
  phoneNumber: string;
  whatsApp: string;
};

export type ILayoutSettings = {
  id: number;
  attributes: {
    footerNavigation?: NavigationItem[];
    headerNavigation?: NavigationItem[];
    footer?: FooterData;
    header?: HeaderData;
    internationalization?: InternationalizationData;
    supportEmail?: string;
    supportPhoneNumber?: string;
    socialNetworks?: Array<SocialLink>;
    ammanaFooterLegal?: AmmanaFooterLegalData;
    features?: AppFeatures;
    repayment?: RepaymentOptions;
  };
};

export const footerPopulate = {
  footer: {
    populate: [
      "footerContact",
      "socialLinks",
      "socialLinks.icon",
      "footerContact.icon",
      "footerContact.contact",
    ],
  },
};

export class CommonApi extends ApiInstance {
  constructor(locale: string) {
    super(locale);
  }

  async getLayoutSettings() {
    const query = {
      populate: {
        headerNavigation: {
          populate: "*",
        },
        footerNavigation: {
          populate: "*",
        },

        ...footerPopulate,

        header: {
          populate: "*",
        },
        internationalization: {
          populate: "*",
        },
        socialNetworks: {
          populate: "*",
        },
        ammanaFooterLegal: {
          populate: ["image1", "image2", "image3"],
        },
        features: {
          populate: ["chatbot.buttonImage", "chatbot"],
        },
        repayment: {
          populate: "*",
        },
      },
    };

    return this._SendWithQuery<ILayoutSettings>("/layout-setting", query);
  }
}
